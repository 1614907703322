import React from "react"
import Header from "components/header"
import Footer from "components/footer"
import Transition from "components/transition"
import MobileNav from "./navigation/mobile-nav"

const Layout = (pageProps) => (
    <>
        <Header location={pageProps.location} onScrollToQuiz={pageProps.onScrollToQuiz} />
        <Transition location={pageProps.location} className={pageProps.className}>
            { pageProps.children }
        </Transition>
        <MobileNav location={pageProps.location} onScrollToQuiz={pageProps.onScrollToQuiz} />
        <Footer />
    </>
)

export default Layout
