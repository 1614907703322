import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const SocialNav = ({ className }) => {
    const data = useStaticQuery(graphql`
        query {
            allContentJson {
                edges {
                  node {
                    global {
                      social {
                        facebook {
                            name
                            icon
                            url
                            shortUrl
                        }
                        twitter {
                            name
                            icon
                            url
                            shortUrl
                        }
                        instagram {
                            name
                            icon
                            url
                            shortUrl
                        }
                        linkedin {
                            name
                            icon
                            url
                            shortUrl
                        }
                      }
                    }
                  }
                }
            }
        }
    `)
    const content = data.allContentJson.edges[0].node.global.social

    return (
        <ul className={"u-flex-center-end u-reset-list " + (className ? className: '')}>
            <li className="u-flex u-mg-left-s">
                <a href={content.facebook.url} target="_blank" aria-label={content.facebook.name} alt={content.facebook.name} rel="noopener noreferrer" className="u-pd-hz-s">
                    <span className={content.facebook.icon}></span>
                </a>
            </li>
            <li className="u-flex u-mg-left-s">
                <a href={content.twitter.url} target="_blank" aria-label={content.twitter.name} alt={content.twitter.name} rel="noopener noreferrer" className="u-pd-hz-s">
                    <span className={content.twitter.icon}></span>
                </a>
            </li>
            <li className="u-flex u-mg-left-s">
                <a href={content.instagram.url} target="_blank" aria-label={content.instagram.name} alt={content.instagram.name} rel="noopener noreferrer" className="u-pd-hz-s">
                    <span className={content.instagram.icon}></span>
                </a>
            </li>
            <li className="u-flex u-mg-left-s">
                <a href={content.linkedin.url} target="_blank" aria-label={content.linkedin.name} alt={content.linkedin.name} rel="noopener noreferrer" className="u-pd-hz-s">
                    <span className={content.linkedin.icon}></span>
                </a>
            </li>
        </ul>

    )
}

export default SocialNav
