import React from 'react'
import ImgPolyfill from "gatsby-image/withIEPolyfill"
import { useStaticQuery, graphql } from "gatsby"

const Logo = React.memo(({ className }) => {
    const data = useStaticQuery(graphql`
        query {
            logoImg: file(relativePath: { eq: "images/logo-secours-catholique.png" }) {
                childImageSharp {
                    fixed(width: 58, height: 60, quality: 90, webpQuality: 90) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                }
            },
        }
    `)
    return (
        <ImgPolyfill
            fixed={data.logoImg.childImageSharp.fixed}
            fadeIn={false}
            className={"c-logo " + (className || "")}
            height="60"
            width="58"
            loading="eager"
            alt={"logo Secours Populaire"}
        />
    )
})

export default Logo;


