import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { ButtonCookiesModal } from "components/modals/cookies-modal"
import DefaultLink from "components/defaultLink"

const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            allContentJson {
                edges {
                    node {
                        global {
                            name
                            footer {
                              sup {
                                newsletter {
                                    label
                                    link
                                }
                                contact {
                                    label
                                    icon
                                    link
                                }
                                about {
                                    label
                                    icon
                                    link
                                }
                                brand {
                                    label
                                    icon
                                    link
                                }
                              }
                              sub {
                                copyright
                                policy
                                cookies
                              }
                            }
                            social {
                                facebook {
                                    name
                                    icon
                                    url
                                    shortUrl
                                    appUri
                                    intentUri
                                }
                                twitter {
                                    name
                                    icon
                                    url
                                    shortUrl
                                    appUri
                                    intentUri
                                }
                                instagram {
                                    name
                                    icon
                                    url
                                    shortUrl
                                    appUri
                                    intentUri
                                }
                                linkedin {
                                    name
                                    icon
                                    url
                                    shortUrl
                                    appUri
                                    intentUri
                                }
                              }
                        }
                    }
                }
            }
        }
    `)
    const content = data.allContentJson.edges[0].node.global

    return (
        <footer className={"c-footer"}>
            <div className="c-footer__sup u-pd-vt-l u-pd-hz-m">
                <div className="l-container">
                    <div className="l-grid">
                        <div className="l-col-12 l-col-6@main u-flex u-flex-dir-col u-flex-dir-row@main u-flex-between u-flex-wrap u-mg-bottom-l u-mg-0@main">
                            <DefaultLink link={content.footer.sup.newsletter.link} className={"c-btn c-btn--secondary u-pd-hz-l u-mg-bottom-m u-mg-0@main"}>
                                <span className="u-font-adjustment">{content.footer.sup.newsletter.label}</span>
                            </DefaultLink>
                            <DefaultLink link={content.footer.sup.contact.link} className={"c-btn c-btn--secondary-invert u-pd-hz-l"}>
                                <span className="u-font-adjustment">{content.footer.sup.contact.label}</span>
                                <span className={content.footer.sup.contact.icon + " u-white u-mg-left-s"}></span>
                            </DefaultLink>
                        </div>

                        <div className="l-col-12 l-col-6@main">
                            <div className="l-grid">
                                <div className="l-col-12 l-col-6@main u-flex u-flex-dir-col u-flex-center-vt@main u-flex-center-hz@main u-mg-bottom-l u-mg-0@main">
                                    <DefaultLink link={content.footer.sup.about.link} className={"c-footer__link u-mg-bottom-m"}>
                                        <span className={content.footer.sup.about.icon + " u-secondary u-fs-l u-mg-right-m"}></span>
                                        <span className="u-white u-font-heading u-bold u-uppercase">{content.footer.sup.about.label}</span>
                                    </DefaultLink>
                                    <DefaultLink link={content.footer.sup.brand.link} className={"c-footer__link"}>
                                        <span className={content.footer.sup.brand.icon + " u-secondary u-fs-l u-mg-right-m"}></span>
                                        <span className="u-white u-font-heading u-bold u-uppercase">{content.footer.sup.brand.label}</span>
                                    </DefaultLink>
                                </div>

                                <div className="l-col-12 l-col-6@main">
                                    <ul className={"u-reset-list"}>
                                        {['facebook', 'twitter', 'instagram', 'linkedin'].map(service => (
                                            <li className="u-flex u-mg-left-s" key={`social-${service}`}>
                                                <a
                                                    href={content.social[service].url}
                                                    target="_blank"
                                                    alt={content.social[service].name}
                                                    rel="noopener noreferrer"
                                                    className="u-flex u-flex-center-vt u-width-full u-mg-bottom-m"
                                                    onClick={e => {
                                                        if (typeof window !== "undefined" && typeof navigator?.userAgent !== "undefined") {
                                                            setTimeout(() => {
                                                                if (/Android/i.test(navigator.userAgent) && content.social[service]?.intentUri) {
                                                                    window.open(content.social[service].intentUri)
                                                                }
                                                                else if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream && content.social[service]?.appUri) {
                                                                    window.open(content.social[service].appUri)
                                                                }
                                                            }, 400)
                                                        }
                                                    }}
                                                >
                                                    <span className={content.social[service].icon + " u-fs-l u-mg-right-m"}></span>
                                                    <span className="u-ellipsis">{content.social[service].shortUrl}</span>
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="c-footer__sub u-pd-vt-l u-pd-hz-m">
                <div className="l-container">
                    <div className="u-flex u-flex-dir-col u-flex-dir-row@main u-flex-between">
                        <div className="u-flex u-flex-dir-col u-flex-dir-row@main">
                            <Link to="/mentions-legales" className="u-bold  u-mg-bottom-m u-mg-bottom-0@main u-mg-right-l@main">{content.footer.sub.policy}</Link>
                            <ButtonCookiesModal btnClassName={"u-bold u-mg-bottom-m u-mg-0@main"} label={content.footer.sub.cookies} />
                        </div>
                        <p className="">{content.footer.sub.copyright}</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
