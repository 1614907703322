import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { ShareButton } from "components/shareButton"
import DefaultLink from "components/defaultLink"

const Nav = ({ onScrollToQuiz, location, className }) => {
    const data = useStaticQuery(graphql`
        query {
            allContentJson {
                edges {
                  node {
                    global {
                      header {
                        navigation {
                          quiz {
                              label
                              icon
                              link
                          }
                          donate {
                            label
                            icon
                            link
                          }
                          share {
                            label
                            icon
                          }
                          newsletter {
                            label
                            icon
                            link
                          }
                        }
                      }
                    }
                  }
                }
            }
        }
    `)
    const { quiz, donate, share, newsletter } = data.allContentJson.edges[0].node.global.header.navigation

    return (
        <ul className={'u-flex-1 u-flex-around u-flex-center-hz@main u-reset-list ' + (className ? className : '')}>
            <li className="u-flex u-pd-hz-l@main">
                {location && location.pathname === '/' ? (
                  <DefaultLink onClick={() => onScrollToQuiz()} className={"c-header__link"}>
                    <span className={quiz.icon + ' u-primary u-mg-right-m@main'}></span>
                    <span className="u-font-adjustment">{quiz.label}</span>
                  </DefaultLink>
                ) : (
                  <DefaultLink link={quiz.link} className={"c-header__link"}>
                    <span className={quiz.icon + ' u-primary u-mg-right-m@main'}></span>
                    <span className="u-font-adjustment">{quiz.label}</span>
                  </DefaultLink>
                )}
            </li>
            <li className="u-flex u-pd-hz-l@main">
                <DefaultLink link={donate.link} className={"c-header__link"}>
                    <span className={donate.icon + ' u-primary u-mg-right-m@main'}></span>
                    <span className="u-font-adjustment">{donate.label}</span>
                </DefaultLink>
            </li>
            <li className="u-flex u-pd-hz-l@main">
                <ShareButton className="c-header__link" shareTitle={"Partager le quiz"}>
                    <span className={share.icon + ' u-primary u-mg-right-m@main'}></span>
                    <span className="u-font-adjustment">{share.label}</span>
                </ShareButton>
            </li>
            <li className="u-flex u-pd-hz-l@main">
                <DefaultLink link={newsletter.link} className={"c-header__link"}>
                    <span className={newsletter.icon + ' u-primary u-mg-right-m@main'}></span>
                    <span className="u-font-adjustment">{newsletter.label}</span>
                </DefaultLink>
            </li>
        </ul>
    )
}

export default Nav
