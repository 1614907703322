import React from "react"
import Nav from "./nav"

const MobileNav = ({ onScrollToQuiz, location }) => (
    <div className="c-mobile-nav">
        <Nav className="u-flex u-hide@main" onScrollToQuiz={onScrollToQuiz} location={location} />
    </div>
)

export default MobileNav
