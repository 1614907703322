import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Logo from "./logo"
import Nav from "components/navigation/nav"
import SocialNav from "components/navigation/social-nav"
import { useScrollPosition } from "utils/scrollPosition"

const Header = ({ onScrollToQuiz, location, className }) => {
    const data = useStaticQuery(graphql`
        query {
            allContentJson {
                edges {
                    node {
                        global {
                            header {
                                back {
                                    label
                                    icon
                                    link
                                }
                            }
                        }
                    }
                }
            }
        }
    `)
    const { label, icon, link } = data.allContentJson.edges[0].node.global.header.back

    // Nav appearance
    const [navTranslate, setNavTranslate] = useState('0')
    useScrollPosition(({ prevPos, currPos }) => {
        setNavTranslate(
            currPos.y >= -120 ?
                currPos.y + 'px'
                : currPos.y > prevPos.y ?
                    '0'
                    : '-100%'
        )
    }, [navTranslate])

    return (
        <header className={"c-header u-bg-secondary " + (className ? className : '') } style={{ transform: `translateY(${navTranslate})` }}>
            <div className="u-flex u-flex-center-hz u-bg-white u-pd-s">
                <a href={link} className="u-flex u-flex-center-vt" target="_blank" alt={label} rel="noopener noreferrer">
                    <span className="u-primary u-fs-xs">{label}</span>
                    <span className={icon + ' u-secondary u-mg-left-s'}></span>
                </a>
            </div>

            <div className="l-container u-pd-vt-m">
                <div className="u-flex u-flex-center-vt u-flex-center-hz u-flex-between@main">
                    <Link to="/" aria-label="logo" className="u-br-sc u-overflow-hidden u-flex-0">
                        <Logo />
                    </Link>
                    <Nav className="c-header__nav u-hide u-flex@main" onScrollToQuiz={onScrollToQuiz} location={location} />
                    <SocialNav className="c-header__social u-fs-l u-white u-hide u-flex@main" />
                </div>
            </div>
        </header>
    )
}

export default Header